import React, {useEffect, useLayoutEffect, useRef} from "react";
// @ts-ignore
import PhotoSwipeLightbox from 'photoswipe/lightbox';
import PhotoSwipe from "photoswipe";
import styles from "./gallery.module.scss";

export interface GalleryPicture {
  url: string;
  thumbnail: string;
  width: number;
  height: number;
  thumbHeight: number;
  thumbWidth: number;
}

export type GalleryProps = {
  pictures: GalleryPicture[]
};

export default function Gallery({pictures}: GalleryProps) {
  const galleryRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    let lightbox: PhotoSwipeLightbox | null = new PhotoSwipeLightbox({
      gallery: galleryRef.current!!,
      children: 'a',
      pswpModule: PhotoSwipe,
      spacing: 0.3,
      easing: 'cubic-bezier(.4,0,.22,1)',
      showHideAnimationType: 'fade',
    });
    lightbox.init();

    return () => {
      lightbox!!.destroy();
      lightbox = null;
    }
  }, []);

  return <div ref={galleryRef} className={styles.galleryContainer}>
    {
      pictures.map(p => (
          <a
              href={p.url}
              key={p.url}
              data-pswp-width={p.width}
              data-pswp-height={p.height}
              target="_blank" rel="noreferrer">
            <img src={p.thumbnail}
                 style={{
                   height: p.thumbHeight,
                   maxWidth: p.thumbWidth,
                   width: '100%',
                   objectFit: "cover",
                   margin: ".5em"
            }} alt="" />
          </a>
      ))
    }
  </div>;
}