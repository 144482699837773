import React from "react";
import styles from "./header.module.scss";

export type HeaderProps = {
};

export default function Header() {
  return <header className={styles.container}>
    <h3 className={styles.title}>
      Paul Henry
    </h3>
    <h4 className={styles.subtitle}>drone photography</h4>
  </header>;
}