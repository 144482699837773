import React from 'react';
import './App.scss';
import MainContainer from "./layout/MainContainer";
import Header from "./sections/Header";
import Separator from "./components/Separator";
import Socials from "./sections/Socials";
import Equipment from "./sections/Equipment";
import Pics from "./configuration/pics";
import VideoGallery from "./sections/VideoGallery";
import Videos from "./configuration/videos";
import Gallery from "./sections/Gallery";


function App() {
  return (
      <MainContainer>
        <Header/>
        <Separator/>
        <Gallery pictures={Pics}/>
        <Separator/>
        <VideoGallery videos={Videos}/>
        <Separator/>
        <Equipment/>
        <Separator/>
        <Socials/>
      </MainContainer>
  );
}

export default App;
