import React from "react";
import styles from "./video-gallery.module.scss"

export interface Video {
  title: string;
  id: string;
}

export interface VideoGalleryProps {
  videos: Video[];
  autoplay?: boolean;
  fullScreenAllowed?: boolean;
  loop?: boolean;
}

export default function VideoGallery({videos, autoplay = false, fullScreenAllowed = true, loop = false}: VideoGalleryProps) {
  const params = new URLSearchParams();
  params.append('autoplay', autoplay ? '1' : '0');
  params.append('disablekb', '1');
  params.append('modestbranding', '1');
  params.append('rel', '0');
  params.append('fs', fullScreenAllowed ? '1' : '0');
  params.append('loop', loop ? '1' : '0');

  return <div className={styles.galleryContainer}>
    {videos.map(v =>
        <div key={v.id} className={styles.galleryElement}>
          <iframe className={styles.galleryFrame} width="480" height="270" src={`https://www.youtube.com/embed/${v.id}?${params.toString()}`}
                  title={v.title} frameBorder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                  allowFullScreen></iframe>
        </div>
    )}
  </div>
}