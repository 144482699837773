import React from "react";
import styles from "./equipment.module.scss";

const equipment = [
  {
    name: "DJI FPV",
    description: "Powerful and robust drone that can be flown in ACRO mode for action-packed footage. Best for action footage, long range shots and chases",
    image: "assets/images/dji_fpv_combo.jpg"
  },
  {
    name: "DJI AIR 3",
    description: "Perfectly crafted stabilized drone, best for cinematic and descriptive shots. Very powerful and superb image quality.",
    image: "assets/images/air3.jpg"
  }
]

export default function Equipment() {
  return <>
    <h2>Fleet</h2>
    <div className={styles.equipmentContainer}>
      { equipment.map(e => (
          <div key={e.name} className={styles.equipmentItem}>
            <img className={styles.equipmentPicture} src={e.image} />
            <h3>{e.name}</h3>
            <p className={styles.equipmentDescription}>{e.description}</p>
          </div>
          ))
      }
    </div>
  </>
}