import {Video} from "../sections/VideoGallery";

const Videos: Video[] = [
  {
    title: '#Cilento cinematic: 1. Inland, sea and urban',
    id: 'fiyCRT1rDjE'
  },
  {
    title: '#Cilento cinematic: 2. The mountain and the sea',
    id: 'PB6J3hbIm_M'
  },
  {
    title: '#Cilento cinematic: 3. Time flows',
    id: 'uoe8MLBj50g'
  }
];

export default Videos;