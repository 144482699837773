import React from "react";
import styles from "./socials.module.scss";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faYoutube, faInstagram, faTiktok} from "@fortawesome/free-brands-svg-icons";
import {IconDefinition} from "@fortawesome/fontawesome-svg-core";

interface SocialType {
  url: string;
  social: IconDefinition
}

const socials: SocialType[] = [
  {
    url: "https://www.youtube.com/channel/UCW0gRBYDbQU_HuDiIAblP5A",
    social: faYoutube
  },
  {
    url: "https://instagram.com/pabloinfantescobar?igshid=OGQ5ZDc2ODk2ZA==",
    social: faInstagram
  },
  {
    url: "https://www.tiktok.com/@paul.henry.0",
    social: faTiktok
  }
]

export default function Socials() {
  return <div className={styles.socialsContainer}>
    {socials.map(s => (
        <a href={s.url} target="_blank" rel="noreferrer">
          <FontAwesomeIcon icon={s.social} className={styles.socialIcon} />
        </a>
    ))
    }
  </div>
}