const Pics = [
  {
    url: "assets/pictures/1.jpg",
    thumbnail: "assets/pictures/thumbs/1.jpg",
    width: 8064,
    height: 4536,
    thumbWidth: 400,
    thumbHeight: 453
  },
  {
    url: "assets/pictures/2.jpg",
    thumbnail: "assets/pictures/thumbs/2.jpg",
    width: 8064,
    height: 4536,
    thumbWidth: 300,
    thumbHeight: 453
  },
  {
    url: "assets/pictures/3.jpg",
    thumbnail: "assets/pictures/thumbs/3.jpg",
    width: 8064,
    height: 4536,
    thumbWidth: 320,
    thumbHeight: 453
  },
  {
    url: "assets/pictures/4.jpg",
    thumbnail: "assets/pictures/thumbs/4.jpg",
    width: 8064,
    height: 4536,
    thumbWidth: 380,
    thumbHeight: 453
  }
];

export default Pics;